<template>
  <div class="content-wrapper">
    <h1>Competenze</h1>
    <b-card class="mb-4">
      <b-row class="my-1">
        <b-col>
          <b-input-group size="sm" class="mb-2">
            <b-input-group-prepend is-text>
              <b-icon icon="search"></b-icon>
            </b-input-group-prepend>
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Search"
            ></b-form-input>
          </b-input-group>
        </b-col>
        <b-col class="text-lg-right">
          <b-button size="sm" class="mb-2" v-b-toggle.sidebar-competenze>
            <b-icon icon="plus" aria-hidden="true"></b-icon> New competence
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <b-alert v-model="deleteNotPossible" variant="warning" dismissible>
        Attention! Is not possible to delete the selected Competence. It may be
        in use by some Role
      </b-alert>

      <div v-if="loadingGetData || loadingSaveData" class="w-100 text-center">
        <b-spinner variant="primary" label="Spinning"></b-spinner>
      </div>

      <b-table
        v-else
        hover
        :items="items"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="filter"
      >
        <template #cell(actions)="row">
          <b-dropdown
            size="sm"
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template #button-content>
              <b-icon-gear-fill></b-icon-gear-fill>
            </template>
            <b-dropdown-item
              href="#"
              v-b-toggle.sidebar-competenze
              @click="
                currentItem = row.item;
                tempItem = JSON.parse(JSON.stringify(row.item));
              "
              >Edit</b-dropdown-item
            >
            <b-dropdown-item
              href="#"
              @click="
                currentItem = row.item;
                showDeleteModal = true;
              "
              >Delete</b-dropdown-item
            >
          </b-dropdown>
        </template>
        <template #cell(durataAcquisizione)="row">
          <span class="font-bold">{{ row.item.durataAcquisizione }} month</span>
        </template>
        <template #cell(idCompany)="row">
          {{ companyNameById(row.item.idCompany) }}
        </template>
      </b-table>
    </b-card>

    <b-sidebar
      class="right-sidebar"
      id="sidebar-competenze"
      right
      shadow
      lazy
      @hidden="resetData"
    >
      <div class="content-wrapper my-3 mx-1">
        <h4 v-if="tempItem && tempItem.nome">
          {{ tempItem.nome }}
        </h4>
        <h4 v-else>New Competence</h4>
      </div>

      <b-row class="px-4">
        <b-col cols="12">
          <b-form-group label="Company" label-for="company">
            <b-form-select
              id="company"
              v-model="tempItem.idCompany"
              :options="companyList"
              size="sm"
            ></b-form-select>
          </b-form-group>
        </b-col>

        <b-col cols="12"> </b-col>

        <b-col cols="12">
          <b-form-group label="Name" label-for="nome">
            <b-form-input id="nome" v-model="tempItem.nome" />
          </b-form-group>
        </b-col>

        <b-col cols="12" class="my-2">
          <hr class="solid" />
        </b-col>

        <b-col cols="12">
          <b-form-group
            label="Competence acquisition duration (Months)"
            label-for="durataAcquisizione"
          >
            <b-form-input
              id="durataAcquisizione"
              type="number"
              v-model="tempItem.durataAcquisizione"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" class="my-2">
          <hr class="solid" />
        </b-col>

        <b-col cols="12">
          <h4>Minimum number of badges</h4>
        </b-col>

        <b-col cols="2">
          <b-form-group label="KPI" label-for="numBadgeKPI">
            <b-form-input
              id="numBadgeKPI"
              type="number"
              v-model="tempItem.numBadgeKPI"
            />
          </b-form-group>
        </b-col>

        <b-col cols="2">
          <b-form-group label="TASK" label-for="numBadgeTask">
            <b-form-input
              id="numBadgeTask"
              type="number"
              v-model="tempItem.numBadgeTask"
            />
          </b-form-group>
        </b-col>

        <b-col cols="2">
          <b-form-group label="LEARNING" label-for="numBadgeLearning">
            <b-form-input
              id="numBadgeLearning"
              type="number"
              v-model="tempItem.numBadgeLearning"
            />
          </b-form-group>
        </b-col>

        <b-col cols="2">
          <b-form-group label="LANDING" label-for="numBadgeLanding">
            <b-form-input
              id="numBadgeLanding"
              type="number"
              v-model="tempItem.numBadgeLanding"
            />
          </b-form-group>
        </b-col>

        <b-col cols="2">
          <b-form-group label="SOCIAL" label-for="numBadgeSocial">
            <b-form-input
              id="numBadgeSocial"
              type="number"
              v-model="tempItem.numBadgeSocial"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" class="my-2">
          <hr class="solid" />
        </b-col>

        <b-col cols="12" class="mt-4">
          <b-button
            squared
            variant="primary"
            size="lg"
            class="big-button"
            @click="saveItem"
            >Confirm</b-button
          >
        </b-col>
      </b-row>
    </b-sidebar>

    <b-modal
      v-model="showDeleteModal"
      id="delete-modal"
      ref="delete-modal"
      title="Confirm Deletion"
      ok-title="Delete"
      ok-variant="danger"
      @ok="deleteItem"
    >
      Are you sure you want to delete this competence?
    </b-modal>
  </div>
</template>

<script>
import AxiosService from "../axiosServices/AxiosService";
import { SET_CURRENT_COMPANY } from "../store/actions/config";

export default {
  name: "Competenze",
  async mounted() {
    await this.getCompanies();
    this.getData();
  },
  data() {
    return {
      companyList: [],
      loadingGetData: false,
      loadingSaveData: false,
      showDeleteModal: false,
      filter: null,
      currentPage: 1,
      perPage: 10,
      fields: [
        {
          key: "actions",
          label: "Actions",
        },
        {
          key: "nome",
          label: "Name",
        },
        {
          key: "durataAcquisizione",
          label: "Acquisition duration",
        },
        {
          key: "idCompany",
          label: "Company",
        },
        {
          key: "numBadgeKPI",
          label: "Min. KPI badges",
        },
        {
          key: "numBadgeTask",
          label: "Min. Task badges",
        },
        {
          key: "numBadgeLearning",
          label: "Min. Learning badges",
        },
        {
          key: "numBadgeLanding",
          label: "Min. Landing badges",
        },
        {
          key: "numBadgeSocial",
          label: "Min. Social badges",
        },
      ],
      items: [],
      currentItem: {
        id: null,
        nome: null,
        percCompletamento: null,
      },
      tempItem: {
        id: null,
        nome: null,
        percCompletamento: null,
        isRenewable: false,
        renewalPeriod: null,
      },

      deleteNotPossible: false,
    };
  },
  computed: {
    currentCompanyFromStore() {
      return this.$store.getters.currentCompany;
    },
  },
  watch: {
    currentCompanyFromStore() {
      this.tempItem.idCompany = this.currentCompanyFromStore;
    },
  },
  methods: {
    async getCompanies() {
      const service = new AxiosService("DossierManager/GetCustomers");
      this.companyList.splice(0);
      this.companyList.push({ value: null, text: "All companies" });
      try {
        let companies = await service.getOptions("Company");
        this.companyList.push(...companies);
        this.tempItem.idCompany = this.currentCompanyFromStore;
      } catch (error) {
        console.log(error);
      }
    },
    companyNameById(idCompany) {
      let company = this.companyList.find(
        (company) => company.value == idCompany
      );
      return company.text;
    },
    saveItem() {
      const servicePost = new AxiosService("Competenza/Post");
      const servicePut = new AxiosService("Competenza/Put");
      this.currentItem = { ...this.tempItem };
      this.loadingSaveData = true;

      if (this.currentItem.id) {
        servicePut
          .update(this.currentItem)
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.$root.$emit("bv::toggle::collapse", "sidebar-competenze");
            this.loadingSaveData = false;
            this.getData();
            this.resetData();
          });
      } else {
        servicePost
          .create(this.currentItem)
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.$root.$emit("bv::toggle::collapse", "sidebar-competenze");
            this.loadingSaveData = false;
            this.getData();
            this.resetData();
          });
      }
    },
    resetData() {
      this.$store.dispatch(SET_CURRENT_COMPANY, this.tempItem.idCompany);
      this.currentItem = {
        id: null,
        nome: null,
        percCompletamento: null,
      };
      this.tempItem = {
        id: null,
        nome: null,
        percCompletamento: null,
        isRenewable: false,
        renewalPeriod: null,
      };
    },
    getData() {
      const service = new AxiosService("Competenza/Get");
      this.loadingGetData = true;
      try {
        service
          .read()
          .then((response) => {
            this.items = response;
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingGetData = false;
      }
    },
    deleteItem() {
      const service = new AxiosService("Competenza/Delete");
      this.loadingSaveData = true;
      service
        .delete(this.currentItem.id)
        .then((response) => {
          if (!response) {
            this.deleteNotPossible = true;
          }

          setTimeout(() => {
            this.deleteNotPossible = false;
          }, 5000);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingSaveData = false;
          this.getData();
          this.resetData();
        });
    },
  },
};
</script>
